
import { Vue, Component, Prop, VModel } from 'vue-property-decorator';
@Component
export default class ConfirmationDialog extends Vue{
  @VModel({ required: true }) show!: boolean;
  @Prop({ required: true }) question!: string;
  @Prop({ required: false, default: '' }) text!: string;
  @Prop({ required: false, default: 'Confirm' }) confirmBtnText!: string;
  @Prop({ required: false, default: 'Cancel' }) cancelBtnText!: string;
  @Prop({ required: false, default: false }) loading!: boolean;
}
